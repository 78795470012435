import React, { Fragment, useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';

// services
import {
  alertAdminOfListingInterestUnregisteredUser,
  alertAdminOfListingInterestPrequalifiedUser,
} from '../../utils/services/homebuyer.service';

// context
import { useHomeBuyerAuth } from '../../context/HomebuyerContext';
import { useAuth } from '../../context/AuthContext';
import { useLandlord } from '../../context/LandlordContext';
// components
import Footer from './Footer';

// chakra
import {
  Box,
  Flex,
  Text,
  Container,
  SimpleGrid,
  Card,
  CardBody,
  Image,
  Grid,
  GridItem,
  Stack,
  StackDivider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Input,
} from '@chakra-ui/react';

const ListingSection = ({ id }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: newUserIsOpen,
    onOpen: newUserOnOpen,
    onClose: newUserOnClose,
  } = useDisclosure();
  const toast = useToast();

  const { applicationData } = useHomeBuyerAuth();
  const { authUser } = useAuth();
  const { getPropertyById } = useLandlord();

  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);
  const [buttonData, setButtonData] = useState({
    text: 'Get Pre-qualified',
    color: 'blue',
    disabled: false,
    handleClick: async () => {
      navigate('/signup/homebuyer');
    },
  });

  const [propertyData, setPropertyData] = useState(null);
  const [propertyImages, setPropertyImages] = useState([]);

  const [homePrice, setHomePrice] = useState(null);
  const [rent, setRent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const property = await getPropertyById(id);
      setPropertyData(property?.data);
      setPropertyImages(property?.images);
      setHomePrice(property?.data?.stepThree[0]?.answer);
      setRent(property?.data?.stepOne[3]?.answer);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          authUser &&
          authUser?.data?.type === 'home_owner' &&
          authUser?.data?.applicationCompleted === true
        ) {
          let budget = 0;
          budget =
            applicationData?.stepFive[0].answer === 'yes'
              ? Math.min(
                  4.358 *
                    (parseInt(applicationData.stepSix[0]?.answer) +
                      parseInt(applicationData.stepSix[4]?.answer)) *
                    12,
                  498990
                )
              : Math.min(
                  4.358 * parseInt(applicationData?.stepSix[0]?.answer) * 12,
                  498990
                );
          budget = parseInt(isNaN(budget) ? 0 : budget);

          if (budget >= homePrice) {
            setButtonData({
              text: 'Get Home',
              color: 'blue',
              disabled: false,
              handleClick: async () => {
                // save to firebase

                // send email to admin
                await alertAdminOfListingInterestPrequalifiedUser({
                  email: 'avikonduru@gmail.com',
                  name: 'test',
                  phoneNumber: '1234567890',
                });

                toast({
                  title: 'Notification sent!',
                  description:
                    "We've sent a notification to the Utopia team of your interest in this home.",
                  status: 'success',
                  duration: 9000,
                  isClosable: true,
                  position: 'top-right',
                });

                newUserOnClose();
              },
            });
          } else {
            setButtonData({
              text: 'Home is out of your budget',
              color: 'gray',
              disabled: true,
              handleClick: async () => {},
            });
          }
        } else {
          setButtonData({
            text: 'Get Pre-qualified',
            color: 'blue',
            disabled: false,
            handleClick: async () => {
              newUserOnOpen();
            },
          });
        }
      } catch (err) {
        console.log('Error on useEffect(): ', err);
      }
    };

    fetchData().catch(console.error);
  }, [applicationData]);

  return (
    <Fragment>
      <Flex bg="gray.100" direction="column" flex={1} overflow="auto">
        <Container maxW="container.xl" mb="12">
          <Box pt="4">
            <Carousel responsive={responsive} infinite={true}>
              {propertyImages.map((image, index) => (
                <Box
                  key={index}
                  onClick={() => {
                    setSelectedImage(image?.imageUrl);
                    onOpen();
                  }}
                  _hover={{ cursor: 'pointer' }}
                  px="2"
                >
                  <Image
                    src={image?.imageUrl}
                    style={{
                      width: '100%',
                      height: '400px',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              ))}
            </Carousel>
          </Box>

          <Box pt="5">
            <Grid templateColumns="repeat(7, 1fr)" gap="9">
              <GridItem colSpan={{ base: 7, md: 5 }}>
                <Stack
                  divider={<StackDivider borderColor="gray.300" />}
                  spacing="5"
                >
                  <Stack spacing="3">
                    <Text fontSize="3xl" fontWeight="600">
                      {propertyData?.stepTwo[3]?.answer}
                    </Text>
                    <Text fontSize="xl" fontWeight="500">
                      {propertyData?.stepTwo[1]?.answer},{' '}
                      {propertyData?.stepTwo[0]?.answer}
                    </Text>
                    <Stack
                      direction="row"
                      divider={<StackDivider borderColor="gray.300" />}
                      fontSize="xl"
                      fontWeight="500"
                      color="gray.600"
                    >
                      <Text>{propertyData?.stepOne[1]?.answer} beds</Text>
                      <Text>{propertyData?.stepOne[2]?.answer} baths</Text>
                      <Text>
                        {parseInt(propertyData?.stepOne[4]?.answer)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                        sqft
                      </Text>
                    </Stack>
                    <Text fontSize="xl" fontWeight="500">
                      {propertyData?.stepFour[0]?.answer}
                    </Text>
                  </Stack>

                  <Box>
                    <Text fontSize="2xl" fontWeight="600" mb="3">
                      Facts & Features
                    </Text>
                    <SimpleGrid
                      columns={2}
                      spacing="2"
                      fontSize="lg"
                      fontWeight="500"
                      color="gray.600"
                    >
                      <Text>
                        Year built:{' '}
                        <span style={{ color: 'black' }}>
                          {propertyData?.stepOne[5]?.answer}
                        </span>
                      </Text>
                      <Text>
                        Lot size:{' '}
                        <span style={{ color: 'black' }}>
                          {propertyData?.stepOne[6]?.answer} sqft
                        </span>
                      </Text>
                    </SimpleGrid>
                  </Box>

                  <Box>
                    <Text fontSize="2xl" fontWeight="600" mb="3">
                      Interior
                    </Text>
                    <SimpleGrid
                      columns={2}
                      spacing="2"
                      fontSize="lg"
                      fontWeight="500"
                      color="gray.600"
                    >
                      <Text>
                        Bedrooms:{' '}
                        <span style={{ color: 'black' }}>
                          {propertyData?.stepOne[1]?.answer}
                        </span>
                      </Text>
                      <Text>
                        Bathrooms:{' '}
                        <span style={{ color: 'black' }}>
                          {propertyData?.stepOne[2]?.answer}
                        </span>
                      </Text>
                      <Text>
                        Flooring size:{' '}
                        <span style={{ color: 'black' }}>
                          {parseInt(propertyData?.stepOne[4]?.answer)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                          sqft
                        </span>
                      </Text>
                      <Text>
                        Price Per Foot:{' '}
                        <span style={{ color: 'black' }}>
                          $
                          {(homePrice / propertyData?.stepOne[4]?.answer)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </span>
                      </Text>
                    </SimpleGrid>
                  </Box>

                  <Flex direction="row" justify="flex-end">
                    <Text fontSize="sm" fontWeight="500" color="gray.400">
                      Information above is pulled from third-party services and
                      may not match public records.
                    </Text>
                  </Flex>
                </Stack>
              </GridItem>
              <GridItem colSpan={{ base: 7, md: 2 }}>
                <Card>
                  <CardBody>
                    <Stack
                      spacing="3"
                      divider={<StackDivider borderColor="gray.300" />}
                    >
                      <Box>
                        <Text fontSize="lg" fontWeight="500" color="gray.600">
                          Home price
                        </Text>
                        <Text fontSize="3xl" fontWeight="600">
                          $
                          {homePrice
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </Text>
                      </Box>

                      <Box>
                        <Text fontSize="lg" fontWeight="500" color="gray.600">
                          Rent
                        </Text>
                        <Text fontSize="3xl" fontWeight="600">
                          $
                          {rent
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          /mo
                        </Text>
                      </Box>

                      <Button
                        size="lg"
                        colorScheme={buttonData.color}
                        isDisabled={buttonData.disabled}
                        onClick={buttonData.handleClick}
                      >
                        {buttonData.text}
                      </Button>
                    </Stack>
                  </CardBody>
                </Card>
              </GridItem>
            </Grid>
          </Box>
        </Container>
        <Footer />
      </Flex>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setSelectedImage(null);
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <Image
            src={selectedImage}
            style={{
              width: '100%',
              objectFit: 'cover',
            }}
          />
        </ModalContent>
      </Modal>

      <Modal
        isOpen={newUserIsOpen}
        onClose={() => {
          setSelectedImage(null);
          newUserOnClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add User Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="4">
              <Box>
                <Text>Name</Text>
                <Input />
              </Box>
              <Box>
                <Text>Email</Text>
                <Input />
              </Box>
              <Box>
                <Text>Phone Number</Text>
                <Input />
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              mr={3}
              onClick={async () => {
                toast({
                  title: 'Notification sent!',
                  description:
                    "We've sent a notification to the Utopia team of your interest in this home.",
                  status: 'success',
                  duration: 9000,
                  isClosable: true,
                  position: 'top-right',
                });

                await alertAdminOfListingInterestUnregisteredUser({
                  email: 'avikonduru@gmail.com',
                  name: 'test',
                  phoneNumber: '1234567890',
                });

                newUserOnClose();
                navigate('/signup/homebuyer');
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default ListingSection;
