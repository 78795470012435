import React, { Fragment } from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

// chakra
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  NumberInput,
  Progress,
  Stack,
  Flex,
  Select,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  InputGroup,
  InputLeftElement,
  InputLeftAddon,
  Textarea,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const AddPropertyStepThree = ({
  setActiveStep,
  stepThreeValueOfProperty,
  setStepThreeValueOfProperty,
  stepThreeReasonForValue,
  setStepThreeReasonForValue,
}) => {
  return (
    <Fragment>
      <Formik
        initialValues={{
          valueOfProperty: stepThreeValueOfProperty,
          reasonForValue: stepThreeReasonForValue,
        }}
        validationSchema={yup.object({
          valueOfProperty: yup
            .number()
            .min(
              100000,
              'We are currently only taking homes with a value at or greater than $100,000'
            )
            .max(
              1000000,
              'We are currently only taking homes with a value at or less than $1,000,000'
            )
            .required('Required'),
          reasonForValue: yup
            .string()
            .min(50, 'Your response should at least be 50 characters long')
            .required('Required'),
        })}
        onSubmit={async values => {
          try {
            setStepThreeValueOfProperty(values.valueOfProperty);
            setStepThreeReasonForValue(values.reasonForValue);
            setActiveStep(4);
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing="5">
              <Card bg="white" size="lg">
                <CardBody>
                  <Heading as="h4" size="md" mb="5">
                    Add Property
                  </Heading>
                  <Stack spacing="5">
                    <FormControl
                      isInvalid={
                        formik.errors.valueOfProperty &&
                        formik.touched.valueOfProperty
                      }
                    >
                      <FormLabel color="gray">Value of Property</FormLabel>
                      <InputGroup size="lg">
                        <InputLeftAddon>$</InputLeftAddon>
                        <Field
                          as={Input}
                          borderWidth="2px"
                          size="lg"
                          id="valueOfProperty"
                          name="valueOfProperty"
                          type="number"
                          min={100000}
                          max={1000000}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {formik.errors.valueOfProperty}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        formik.errors.reasonForValue &&
                        formik.touched.reasonForValue
                      }
                    >
                      <FormLabel color="gray">Reason for Value</FormLabel>
                      <Field
                        as={Textarea}
                        borderWidth="2px"
                        size="lg"
                        id="reasonForValue"
                        name="reasonForValue"
                        type="text"
                        min={50}
                      />
                      <FormHelperText>
                        Response must be at least 50 characters long
                      </FormHelperText>
                      <FormErrorMessage>
                        {formik.errors.reasonForValue}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                </CardBody>
              </Card>
              <Stack justify="right" direction="row" spacing="3">
                <Button
                  onClick={() => {
                    setActiveStep(2);
                  }}
                >
                  Prev
                </Button>
                <Button colorScheme="blue" type="submit">
                  Next
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default AddPropertyStepThree;
