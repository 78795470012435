import React, { Fragment, useState, useRef } from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

// chakra
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  NumberInput,
  Progress,
  Stack,
  Flex,
  Select,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  InputGroup,
  InputLeftElement,
  InputLeftAddon,
  Textarea,
  Text,
  IconButton,
  Icon,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import { FiTrash2 } from 'react-icons/fi';

const AddPropertyStepFive = ({
  setActiveStep,
  stepFiveSalesCma,
  setStepFiveSalesCma,
  stepFiveAppraisalReport,
  setStepFiveAppraisalReport,
}) => {
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();

  const salesCmaRef = useRef(null);
  const appraisalReportRef = useRef(null);
  const cancelRef = useRef();

  const [alertDialog, setAlertDialog] = useState('');

  const isValidFileSize = (file, minSizeKB = 5, maxSizeMB = 30) => {
    const fileSizeKB = file.size / 1024;
    const fileSizeMB = fileSizeKB / 1024;
    return fileSizeKB >= minSizeKB && fileSizeMB <= maxSizeMB;
  };

  const isDuplicateFile = (existingFiles, newFile) => {
    return existingFiles.some(
      file => file.name === newFile.name && file.size === newFile.size
    );
  };

  const handleAddMoreFiles = (
    setFieldValue,
    fieldName,
    existingFiles,
    newFiles
  ) => {
    const validAndUniqueFiles = [];

    for (let i = 0; i < newFiles.length; i++) {
      const newFile = newFiles[i];

      if (!isValidFileSize(newFile)) {
        setAlertDialog(
          `${newFile.name} has an invalid file size. File size should be between 5kb and 30mb`
        );
        alertOnOpen();

        continue;
      }

      if (isDuplicateFile(existingFiles, newFile)) {
        setAlertDialog(
          `${newFile.name} is a duplicate file. Please attach a new one`
        );
        alertOnOpen();

        continue;
      }

      validAndUniqueFiles.push(newFile);
    }

    if (validAndUniqueFiles.length > 0) {
      const updatedFiles = [...existingFiles, ...validAndUniqueFiles];
      setFieldValue(fieldName, updatedFiles);
    }
  };

  const handleDeleteFile = (setFieldValue, values, type, index) => {
    if (type === 'sale') {
      const updatedFiles = [...values.saleDoc];
      updatedFiles.splice(index, 1);
      setFieldValue('saleDoc', updatedFiles);
    } else {
      const updatedFiles = [...values.appraisalDoc];
      updatedFiles.splice(index, 1);
      setFieldValue('appraisalDoc', updatedFiles);
    }
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          saleDoc: stepFiveSalesCma,
          appraisalDoc: stepFiveAppraisalReport,
        }}
        validationSchema={yup.object({
          saleDoc: yup
            .array()
            .max(5, 'Max 5 Sales CMA files are allowed')
            .optional(),
          appraisalDoc: yup
            .array()
            .max(5, 'Max 5 Rental CMA files are allowed')
            .optional(),
        })}
        onSubmit={async values => {
          try {
            setStepFiveSalesCma(values.saleDoc);
            setStepFiveAppraisalReport(values.appraisalDoc);

            setActiveStep(6);
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing="5">
              <Card bg="white" size="lg">
                <CardBody>
                  <Heading as="h4" size="md" mb="5">
                    Add Property CMA (Optional)
                  </Heading>
                  <Stack spacing="9">
                    <Box>
                      <Text>
                        Please share the Comparative Market Analysis (CMA) for
                        the subject property
                      </Text>
                      <Text my={2}>
                        <b>For Sales/Rental CMA:</b> Please choose 3-5
                        comparable sales from the last 3 months in 1 mile radius
                        of Subject Property; Expand criteria if not enough comps
                      </Text>
                      <Text>
                        <b>For Appraisal Report:</b> For Appraisal Report:
                        Please attach the detailed appraisal done by the
                        qualified appraiser (if available).
                      </Text>
                    </Box>

                    <FormControl
                      isInvalid={
                        formik.errors.saleDoc && formik.touched.saleDoc
                      }
                    >
                      <FormLabel fontWeight={'semibold'}>
                        Upload Sales/Rental CMA (Optional)
                      </FormLabel>
                      <input
                        type="file"
                        ref={salesCmaRef}
                        id="saleDoc"
                        name="saleDoc"
                        multiple
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/jpg,image/*,.heic"
                        style={{ display: 'none' }}
                        onChange={e => {
                          handleAddMoreFiles(
                            formik.setFieldValue,
                            'saleDoc',
                            formik.values.saleDoc,
                            e.target.files
                          );

                          salesCmaRef.current.value = '';
                          salesCmaRef.current.click();
                        }}
                      />

                      {formik.values.saleDoc.length === 0 ? (
                        <Flex align="center">
                          <Button as="label" htmlFor="saleDoc">
                            <Text>Select Files</Text>
                          </Button>
                        </Flex>
                      ) : (
                        <Box>
                          {formik.values.saleDoc.map((file, index) => (
                            <Flex align="center" key={index} mt={2}>
                              <Button
                                as="label"
                                for="stepFiveSalesCMA"
                                mr="2"
                                w="100%"
                              >
                                <Text textOverflow="ellipsis" mr="2">
                                  {file.name}
                                </Text>
                              </Button>
                              <IconButton
                                colorScheme="red"
                                aria-label="delete file"
                                icon={<Icon as={FiTrash2} />}
                                onClick={() => {
                                  handleDeleteFile(
                                    formik.setFieldValue,
                                    formik.values,
                                    'sale',
                                    index
                                  );
                                }}
                              />
                            </Flex>
                          ))}

                          <Button
                            colorScheme="blue"
                            as="label"
                            htmlFor="saleDoc"
                            mt={2}
                          >
                            Add More Files
                          </Button>
                        </Box>
                      )}

                      <FormHelperText>
                        Please see the instructions as given above
                      </FormHelperText>
                      <FormErrorMessage>
                        {formik.errors.saleDoc}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        formik.errors.appraisalDoc && formik.touched.saleDoc
                      }
                    >
                      <FormLabel fontWeight={'semibold'}>
                        Upload Appraisal Report (Optional)
                      </FormLabel>
                      <input
                        type="file"
                        ref={appraisalReportRef}
                        id="appraisalDoc"
                        name="appraisalDoc"
                        multiple
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/jpg,image/*,.heic"
                        style={{ display: 'none' }}
                        onChange={e => {
                          handleAddMoreFiles(
                            formik.setFieldValue,
                            'appraisalDoc',
                            formik.values.appraisalDoc,
                            e.target.files
                          );

                          appraisalReportRef.current.value = '';
                          appraisalReportRef.current.click();
                        }}
                      />

                      {formik.values.appraisalDoc.length === 0 ? (
                        <Flex align="center">
                          <Button as="label" htmlFor="appraisalDoc">
                            <Text>Select Files</Text>
                          </Button>
                        </Flex>
                      ) : (
                        <Box>
                          {formik.values.appraisalDoc.map((file, index) => (
                            <Flex align="center" key={index} mt={2}>
                              <Button
                                as="label"
                                for="stepFiveAppraisalReport"
                                mr="2"
                                w="100%"
                              >
                                <Text textOverflow="ellipsis" mr="2">
                                  {file.name}
                                </Text>
                              </Button>
                              <IconButton
                                colorScheme="red"
                                aria-label="delete file"
                                icon={<Icon as={FiTrash2} />}
                                onClick={() => {
                                  handleDeleteFile(
                                    formik.setFieldValue,
                                    formik.values,
                                    'appraisal',
                                    index
                                  );
                                }}
                              />
                            </Flex>
                          ))}

                          <Button
                            colorScheme="blue"
                            as="label"
                            htmlFor="appraisalDoc"
                            mt={2}
                          >
                            Add More Files
                          </Button>
                        </Box>
                      )}

                      <FormHelperText>
                        Please see the instructions as given above
                      </FormHelperText>
                      <FormErrorMessage>
                        {formik.errors.appraisalDoc}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                </CardBody>
              </Card>

              <Stack justify="right" direction="row" spacing="3">
                <Button
                  onClick={() => {
                    setActiveStep(4);
                  }}
                >
                  Prev
                </Button>
                <Button
                  colorScheme="blue"
                  // isDisabled={salesCma === '' && appraisalReport === ''}
                  type="submit"
                >
                  Next
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>

      <AlertDialog
        isOpen={alertIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={alertOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Oops!
            </AlertDialogHeader>

            <AlertDialogBody>{alertDialog}</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="gray" onClick={alertOnClose} ml={3}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Fragment>
  );
};

export default AddPropertyStepFive;
