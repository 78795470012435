import React, { useState, useEffect, Fragment } from 'react';

// context
import { useLandlord } from '../../../../../context/LandlordContext';

// components
import AddPropertyStepOne from './AddPropertyStepOne';
import AddPropertyStepTwo from './AddPropertyStepTwo';
import AddPropertyStepThree from './AddPropertyStepThree';
import AddPropertyStepFour from './AddPropertyStepFour';
import AddPropertyStepFive from './AddPropertyStepFive';
import AddPropertyStepSix from './AddPropertyStepSix';
import AddPropertyStepSeven from './AddPropertyStepSeven';

// chakra
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  Progress,
} from '@chakra-ui/react';

const AddPropertyApplication = ({ setApplicationCompleted }) => {
  const { addProperty } = useLandlord();

  const [activeStep, setActiveStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  // form answers
  const [stepOnePropertyType, setStepOnePropertyType] = useState(null);
  const [stepOneNumberOfBedrooms, setStepOneNumberOfBedrooms] = useState(null);
  const [stepOneNumberOfBathrooms, setStepOneNumberOfBathrooms] =
    useState(null);
  const [stepOneMonthlyRent, setStepOneMonthlyRent] = useState(null);
  const [stepOneSquareFeet, setStepOneSquareFeet] = useState(null);
  const [stepOneYearBuilt, setStepOneYearBuilt] = useState(null);
  const [stepOneLotSize, setStepOneLotSize] = useState(null);

  const [stepTwoState, setStepTwoState] = useState(null);
  const [stepTwoMetro, setStepTwoMetro] = useState(null);
  const [stepTwoCity, setStepTwoCity] = useState(null);
  const [stepTwoPropertyAddress, setStepTwoPropertyAddress] = useState(null);

  const [stepThreeValueOfProperty, setStepThreeValueOfProperty] =
    useState(null);
  const [stepThreeReasonForValue, setStepThreeReasonForValue] = useState(null);

  const [stepFourPropertyDescription, setStepFourPropertyDescription] =
    useState(null);

  const [stepFiveSalesCma, setStepFiveSalesCma] = useState([]);
  const [stepFiveAppraisalReport, setStepFiveAppraisalReport] = useState([]);

  const [stepSixPropertyImages, setStepSixPropertyImages] = useState([]);

  const [stepSevenLandlordFirstName, setStepSevenLandlordFirstName] =
    useState(null);
  const [stepSevenLandlordLastName, setStepSevenLandlordLastName] =
    useState(null);
  const [stepSevenLandlordEmail, setStepSevenLandlordEmail] = useState(null);
  const [stepSevenLandlordPhone, setStepSevenLandlordPhone] = useState(null);

  const saveProperty = async userId => {
    try {
      await addProperty(
        userId,
        stepOnePropertyType ? stepOnePropertyType : '',
        stepOneNumberOfBedrooms ? stepOneNumberOfBedrooms : '',
        stepOneNumberOfBathrooms ? stepOneNumberOfBathrooms : '',
        stepOneMonthlyRent ? stepOneMonthlyRent : '',
        stepOneSquareFeet ? stepOneSquareFeet : '',
        stepOneYearBuilt ? stepOneYearBuilt : '',
        stepOneLotSize ? stepOneLotSize : '',
        stepTwoState ? stepTwoState : '',
        stepTwoMetro ? stepTwoMetro : '',
        stepTwoCity ? stepTwoCity : '',
        stepTwoPropertyAddress ? stepTwoPropertyAddress : '',
        stepThreeValueOfProperty ? stepThreeValueOfProperty : '',
        stepThreeReasonForValue ? stepThreeReasonForValue : '',
        stepFourPropertyDescription ? stepFourPropertyDescription : '',
        stepFiveSalesCma,
        stepFiveAppraisalReport,
        stepSixPropertyImages,
        stepSevenLandlordFirstName,
        stepSevenLandlordLastName,
        stepSevenLandlordEmail,
        stepSevenLandlordPhone
      );
    } catch (err) {
      console.log(err);
    }
  };

  const renderStep = activeStep => {
    switch (activeStep) {
      case 1:
        return (
          <AddPropertyStepOne
            setActiveStep={setActiveStep}
            stepOnePropertyType={stepOnePropertyType}
            setStepOnePropertyType={setStepOnePropertyType}
            stepOneNumberOfBedrooms={stepOneNumberOfBedrooms}
            setStepOneNumberOfBedrooms={setStepOneNumberOfBedrooms}
            stepOneNumberOfBathrooms={stepOneNumberOfBathrooms}
            setStepOneNumberOfBathrooms={setStepOneNumberOfBathrooms}
            stepOneMonthlyRent={stepOneMonthlyRent}
            setStepOneMonthlyRent={setStepOneMonthlyRent}
            stepOneSquareFeet={stepOneSquareFeet}
            setStepOneSquareFeet={setStepOneSquareFeet}
            stepOneYearBuilt={stepOneYearBuilt}
            setStepOneYearBuilt={setStepOneYearBuilt}
            stepOneLotSize={stepOneLotSize}
            setStepOneLotSize={setStepOneLotSize}
          />
        );
      case 2:
        return (
          <AddPropertyStepTwo
            setActiveStep={setActiveStep}
            stepTwoState={stepTwoState}
            setStepTwoState={setStepTwoState}
            stepTwoMetro={stepTwoMetro}
            setStepTwoMetro={setStepTwoMetro}
            stepTwoCity={stepTwoCity}
            setStepTwoCity={setStepTwoCity}
            stepTwoPropertyAddress={stepTwoPropertyAddress}
            setStepTwoPropertyAddress={setStepTwoPropertyAddress}
          />
        );
      case 3:
        return (
          <AddPropertyStepThree
            setActiveStep={setActiveStep}
            stepThreeValueOfProperty={stepThreeValueOfProperty}
            setStepThreeValueOfProperty={setStepThreeValueOfProperty}
            stepThreeReasonForValue={stepThreeReasonForValue}
            setStepThreeReasonForValue={setStepThreeReasonForValue}
          />
        );
      case 4:
        return (
          <AddPropertyStepFour
            setActiveStep={setActiveStep}
            stepFourPropertyDescription={stepFourPropertyDescription}
            setStepFourPropertyDescription={setStepFourPropertyDescription}
          />
        );
      case 5:
        return (
          <AddPropertyStepFive
            setActiveStep={setActiveStep}
            stepFiveSalesCma={stepFiveSalesCma}
            setStepFiveSalesCma={setStepFiveSalesCma}
            stepFiveAppraisalReport={stepFiveAppraisalReport}
            setStepFiveAppraisalReport={setStepFiveAppraisalReport}
          />
        );
      case 6:
        return (
          <AddPropertyStepSix
            setActiveStep={setActiveStep}
            stepSixPropertyImages={stepSixPropertyImages}
            setStepSixPropertyImages={setStepSixPropertyImages}
          />
        );

      case 7:
        return (
          <AddPropertyStepSeven
            setActiveStep={setActiveStep}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            stepSevenLandlordFirstName={stepSevenLandlordFirstName}
            setStepSevenLandlordFirstName={setStepSevenLandlordFirstName}
            stepSevenLandlordLastName={stepSevenLandlordLastName}
            setStepSevenLandlordLastName={setStepSevenLandlordLastName}
            stepSevenLandlordEmail={stepSevenLandlordEmail}
            setStepSevenLandlordEmail={setStepSevenLandlordEmail}
            stepSevenLandlordPhone={stepSevenLandlordPhone}
            setStepSevenLandlordPhone={setStepSevenLandlordPhone}
            saveProperty={saveProperty}
            setApplicationCompleted={setApplicationCompleted}
          />
        );
      default:
        return (
          <Box>
            <Text>Something went wrong</Text>
          </Box>
        );
    }
  };

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme={activeStep == 7 ? 'green' : 'blue'}
          rounded="full"
          size="sm"
          value={(activeStep / 7) * 100}
          bg="gray.200"
        />

        {renderStep(activeStep)}
      </Stack>
    </Fragment>
  );
};

export default AddPropertyApplication;
