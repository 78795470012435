import React, { createContext, useContext, useEffect, useState } from 'react';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  listAll,
  getMetadata,
} from 'firebase/storage';
import moment from 'moment';
import { APPLICATION_STATUS, USER_TYPES } from '../utils/constants';
import app from '../utils/firebase';

const LandlordContext = createContext();

const LandlordProvider = ({ children }) => {
  const db = getFirestore(app);
  const storage = getStorage(app);

  const [applicationData, setApplicationData] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);

  const [landlordProperties, setLandlordProperties] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth(app);

      onAuthStateChanged(auth, async user => {
        if (user) {
          const docRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            let data = docSnap.data();

            if (data.type == USER_TYPES.LANDLORD) {
              getUserApplication(user.uid);
            }
          } else {
          }
        } else {
        }
      });
    };

    fetchData().catch(console.error);
  }, []);

  const getUserApplication = async userId => {
    try {
      const docRef = doc(db, 'landlord_application_responses', userId);
      const docSnap = await getDoc(docRef);
      setApplicationData(docSnap.data());
      return docSnap.data();
    } catch (err) {
      console.log(
        '🚀 ~ file: AuthContext.js ~ line 327 ~ getUserApplication ~ err',
        err
      );
    }
  };

  const updateApplicationFields = async updatedField => {
    const auth = getAuth(app);
    const docRef = doc(
      db,
      'landlord_application_responses',
      auth.currentUser.uid
    );
    await setDoc(docRef, updatedField, {
      merge: true,
    });
  };

  const getLandlordProperties = async userId => {
    try {
      const auth = getAuth(app);

      const q = query(
        collection(db, 'landlord_properties'),
        where('userId', '==', userId)
      );
      const querySnapshot = await getDocs(q);
      const properties = querySnapshot.docs.map(doc => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });

      let propertiesData = [];
      for (let i = 0; i < properties.length; i++) {
        const propertyDataId = properties[i].id;

        const propertyImagesRef = ref(
          storage,
          `landlord_property_files/${auth.currentUser.uid}/${propertyDataId}/property_images`
        );

        const docsList = await listAll(propertyImagesRef);

        const propertyImages = [];
        for (const itemRef of docsList.items) {
          const fileMetadata = await getMetadata(itemRef);
          const fileUrl = await getDownloadURL(itemRef);

          propertyImages.push({
            imageUrl: fileUrl,
            imageDescription: fileMetadata?.customMetadata?.propertyDescription,
          });
        }

        const propertyData = properties[i].data;

        propertiesData.push({
          id: propertyDataId,
          data: propertyData,
          images: propertyImages,
        });
      }

      setLandlordProperties(propertiesData);
      return propertiesData;
    } catch (err) {
      console.log('Error in getLandlordProperties');
      console.log(err);
    }
  };

  const addProperty = async (
    userId,
    stepOnePropertyType,
    stepOneNumberOfBedrooms,
    stepOneNumberOfBathrooms,
    stepOneMonthlyRent,
    stepOneSquareFeet,
    stepOneYearBuilt,
    stepOneLotSize,
    stepTwoState,
    stepTwoMetro,
    stepTwoCity,
    stepTwoPropertyAddress,
    stepThreeValueOfProperty,
    stepThreeReasonForValue,
    stepFourPropertyDescription,
    stepFiveSalesCma,
    stepFiveAppraisalReport,
    stepSixPropertyImages,
    stepSevenLandlordFirstName = '',
    stepSevenLandlordLastName = '',
    stepSevenLandlordEmail = '',
    stepSevenLandlordPhone = ''
  ) => {
    console.log('Running addProperty');
    try {
      const auth = getAuth(app);

      const docRef = await addDoc(collection(db, 'landlord_properties'), {
        userId: userId,
        status: 'IN_REVIEW',
        timeSubmitted: Timestamp.now(),
        stepOne: [
          {
            question: 'Property Type',
            answer: stepOnePropertyType,
          },
          {
            question: 'Number of Bedrooms',
            answer: stepOneNumberOfBedrooms,
          },
          {
            question: 'Number of Bathrooms',
            answer: stepOneNumberOfBathrooms,
          },
          {
            question: 'Monthly Rent',
            answer: stepOneMonthlyRent,
          },
          {
            question: 'Square Feet',
            answer: stepOneSquareFeet,
          },
          {
            question: 'Year Built',
            answer: stepOneYearBuilt,
          },
          {
            question: 'Lot Size',
            answer: stepOneLotSize,
          },
        ],
        stepTwo: [
          {
            question: 'Property State',
            answer: stepTwoState,
          },
          {
            question: 'Property Metro',
            answer: stepTwoMetro,
          },
          {
            question: 'Property City',
            answer: stepTwoCity,
          },
          {
            question: 'Property Address or Zillow/Redfin Link',
            answer: stepTwoPropertyAddress,
          },
        ],
        stepThree: [
          {
            question: 'Value of Property',
            answer: stepThreeValueOfProperty,
          },
          {
            question: 'Reason for Value',
            answer: stepThreeReasonForValue,
          },
        ],
        stepFour: [
          {
            question: 'Property Description',
            answer: stepFourPropertyDescription,
          },
        ],
        stepSeven: [
          {
            question: 'Landlord First Name',
            answer: stepSevenLandlordFirstName,
          },
          {
            question: 'Landlord Last Name',
            answer: stepSevenLandlordLastName,
          },
          {
            question: 'Landlord Email',
            answer: stepSevenLandlordEmail,
          },
          {
            question: 'Landlord Phone Number',
            answer: stepSevenLandlordPhone,
          },
        ],
      });
      console.log('Document written with ID: ', docRef.id);

      for (let i = 0; i < stepFiveSalesCma.length; i++) {
        const salesCmaDocRef = ref(
          storage,
          `landlord_property_files/${auth.currentUser.uid}/${docRef.id}/sales_cma_docs/${i}`
        );

        try {
          await uploadBytes(salesCmaDocRef, stepFiveSalesCma[i]);
          console.log(`Sales CMA Doc ${i + 1} Uploaded`);
        } catch (err) {
          console.log(`Error in adding Sales CMA Doc ${i + 1}: ${err}`);
        }
      }

      for (let i = 0; i < stepFiveAppraisalReport.length; i++) {
        const appraisalReportDocRef = ref(
          storage,
          `landlord_property_files/${auth.currentUser.uid}/${docRef.id}/appraisal_report_docs/${i}`
        );

        try {
          await uploadBytes(appraisalReportDocRef, stepFiveAppraisalReport[i]);
          console.log(`Appraisal Report Doc ${i + 1} Uploaded`);
        } catch (err) {
          console.log(`Error in adding Appraisal Report Doc ${i + 1}: ${err}`);
        }
      }

      for (let i = 0; i < stepSixPropertyImages.length; i++) {
        const propertyImageRef = ref(
          storage,
          `landlord_property_files/${auth.currentUser.uid}/${docRef.id}/property_images/${i}`
        );

        try {
          await uploadBytes(propertyImageRef, stepSixPropertyImages[i], {
            customMetadata: {
              propertyDescription: stepSixPropertyImages[i]?.description
                ? stepSixPropertyImages[i]?.description
                : '',
            },
          });
          console.log(`Property Image ${i + 1} Uploaded`);
        } catch (err) {
          console.log(`Error in adding Property Image ${i + 1}: ${err}`);
        }
      }
    } catch (err) {
      console.log('Error in addProperty');
      console.log(err);
    }
  };

  const getAcceptedProperties = async () => {
    try {
      console.log('Running getAcceptedProperties()');

      const q = query(
        collection(db, 'landlord_properties'),
        where('status', '==', 'ACCEPTED')
      );
      const querySnapshot = await getDocs(q);
      const properties = querySnapshot.docs.map(doc => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });

      let propertiesData = [];
      for (let i = 0; i < properties.length; i++) {
        const propertyDataId = properties[i].id;
        const userId = properties[i].data.userId;

        const propertyImagesRef = ref(
          storage,
          `landlord_property_files/${userId}/${propertyDataId}/property_images`
        );

        const docsList = await listAll(propertyImagesRef);

        const propertyImages = [];
        for (const itemRef of docsList.items) {
          const fileMetadata = await getMetadata(itemRef);
          const fileUrl = await getDownloadURL(itemRef);

          propertyImages.push({
            imageUrl: fileUrl,
            imageDescription: fileMetadata?.customMetadata?.propertyDescription,
          });
        }

        const propertyData = properties[i].data;

        propertiesData.push({
          id: propertyDataId,
          data: propertyData,
          images: propertyImages,
        });
      }

      return propertiesData;
    } catch (err) {
      console.log('Error in getAcceptedProperties');
      console.log(err);

      return [];
    }
  };

  const getPropertyById = async id => {
    try {
      const docRef = doc(db, 'landlord_properties', id);
      const docSnap = await getDoc(docRef);

      const propertyId = docSnap.id;
      const propertyData = docSnap.data();

      const propertyImagesRef = ref(
        storage,
        `landlord_property_files/${propertyData.userId}/${propertyId}/property_images`
      );

      const docsList = await listAll(propertyImagesRef);

      const propertyImages = [];
      for (const itemRef of docsList.items) {
        const fileMetadata = await getMetadata(itemRef);
        const fileUrl = await getDownloadURL(itemRef);

        propertyImages.push({
          imageUrl: fileUrl,
          imageDescription: fileMetadata?.customMetadata?.propertyDescription,
        });
      }

      return {
        id: propertyId,
        data: propertyData,
        images: propertyImages,
      };
    } catch (err) {
      console.log('Error in getPropertyById');
      console.log(err);
      return null;
    }
  };

  const value = {
    updateApplicationFields,
    applicationData,
    landlordProperties,
    getLandlordProperties,
    addProperty,
    getAcceptedProperties,
    getPropertyById,
  };

  return (
    <LandlordContext.Provider value={value}>
      {children}
    </LandlordContext.Provider>
  );
};

const useLandlord = () => {
  return useContext(LandlordContext);
};

export { LandlordProvider, useLandlord };
