import React, { Fragment } from 'react';

// components
import HeaderOnboarding from '../components/Coapplicant/HeaderOnboarding';
import LandlordOnboardingComponent from '../components/OnboardingPage/LandlordOnboarding/LandlordOnboarding';

// chakra
import { Box } from '@chakra-ui/react';

const LandlordOnboarding = () => {
  return (
    <Fragment>
      <HeaderOnboarding>
        <Box align="center">
          <LandlordOnboardingComponent />
        </Box>
      </HeaderOnboarding>
    </Fragment>
  );
};

export default LandlordOnboarding;
