import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';

// context
import { useLandlord } from '../../../../context/LandlordContext';
import { useAuth } from '../../../../context/AuthContext';

// chakra
import { useToast } from '@chakra-ui/react';

// components
import LandlordStepOne from './LandlordStepOne';

const LandlordApplication = ({ setApplicationCompleted }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();

  const { authUser, updateUserFields, setAuthLoading } = useAuth();
  const { updateApplicationFields, applicationData } = useLandlord();

  const handleNext = async () => {
    try {
      setButtonLoading(true);
      let payload = {
        uid: authUser.uid,
      };

      let updateUser = false;

      if (activeStep === 1) {
        payload = {
          uid: authUser.uid,
          stepOne: [
            {
              question: 'First Name:',
              answer: firstName,
            },
            {
              question: 'Last Name:',
              answer: lastName,
            },
            {
              question: 'Phone Number:',
              answer: phoneNumber,
            },
          ],
          currentStep: 1,
          applicationCompleted: true,
          timeSubmitted: Timestamp.now(),
        };

        await updateUserFields({
          applicationStarted: true,
        });

        await updateApplicationFields({
          applicationStarted: true,
          isDeleted: false,
          applicationStartedAt: Timestamp.now(),
          email: authUser.email,
          status: 'started',
        });

        updateUser = true;
      }

      await updateApplicationFields(payload);

      if (updateUser) {
        await updateUserFields({
          applicationCompleted: true,
        });

        setButtonLoading(false);

        navigate('/landlord/home');
      }

      setActiveStep(activeStep + 1);
      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
      toast({
        title: 'Error',
        description: 'Something went wrong. Please try again',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const setInitialData = async () => {
    if (applicationData) {
      if (applicationData.stepOne) {
        setFirstName(applicationData.stepOne[0]?.answer);
        setLastName(applicationData.stepOne[1]?.answer);
        setPhoneNumber(applicationData.stepOne[2]?.answer);
      }
    }
  };

  useEffect(() => {
    setInitialData();
  }, [applicationData]);

  const renderStep = activeStep => {
    switch (activeStep) {
      case 1:
        return (
          <LandlordStepOne
            setActiveStep={setActiveStep}
            setFirstName={setFirstName}
            firstName={firstName}
            setLastName={setLastName}
            lastName={lastName}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            handleNext={handleNext}
            loading={buttonLoading}
          />
        );
      default:
        return <LandlordStepOne setActiveStep={setActiveStep} />;
    }
  };

  return <Fragment>{renderStep(activeStep)}</Fragment>;
};

export default LandlordApplication;
