import React, { Fragment, useState, useEffect } from 'react';

// context
import { useLandlord } from '../../../context/LandlordContext';
// components
import ShowcaseCard from './ShowcaseCard';

// chakra
import {
  Box,
  Flex,
  Text,
  Container,
  SimpleGrid,
  Card,
  CardBody,
  Spinner,
} from '@chakra-ui/react';

const ShowcaseSection = () => {
  const { getAcceptedProperties } = useLandlord();

  const [properties, setProperties] = useState([]);
  const [propertiesLoaded, setPropertiesLoaded] = useState(false);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const properties = await getAcceptedProperties();
        setProperties(properties);

        setPropertiesLoaded(true);
      } catch (err) {
        console.log(err);
        setPropertiesLoaded(true);
      }
    };
    fetchProperties();
  }, []);

  return (
    <Fragment>
      <Flex bg="gray.100" direction="column">
        <Container maxW="container.xl">
          <Box py="5">
            <Text fontSize="md" fontWeight="600" color="gray.600">
              {properties.length} homes across the US
            </Text>
          </Box>
        </Container>
      </Flex>
      {propertiesLoaded ? (
        <Flex bg="gray.100" direction="column" flex={1} overflow="auto">
          <Container maxW="container.xl" mb="8">
            <SimpleGrid columns={2} spacing={10}>
              {properties.map(property => (
                <div key={property.id}>
                  <ShowcaseCard
                    id={property.id}
                    property={property.data}
                    images={property.images}
                  />
                </div>
              ))}
            </SimpleGrid>
          </Container>
        </Flex>
      ) : (
        <Flex bg="gray.100" justify="center" align="center" flex={1}>
          <Spinner size="lg" />
        </Flex>
      )}
    </Fragment>
  );
};

export default ShowcaseSection;
