import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
// components
import Navbar from '../components/SearchHomePageComponents/Navbar';
import ListingSection from '../components/ListingPageComponents/ListingSection';

// chakra
import {
  Box,
  Flex,
  Text,
  Container,
  SimpleGrid,
  Card,
  CardBody,
} from '@chakra-ui/react';

const Listing = () => {
  const { id } = useParams();

  return (
    <Fragment>
      <Flex direction="column" w="100%" h="100vh">
        <Navbar />
        <ListingSection id={id} />
      </Flex>
    </Fragment>
  );
};

export default Listing;
