import React, { Fragment } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';

// chakra
import {
  Box,
  Container,
  Text,
  Heading,
  SimpleGrid,
  Card,
  CardBody,
  Stack,
  Flex,
  StackDivider,
} from '@chakra-ui/react';

const ShowcaseCard = ({ id, property, images }) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Card
        boxShadow="sm"
        _hover={{
          //   boxShadow: 'lg',
          bg: 'gray.200',
          cursor: 'pointer',
          //   border: '1px solid',
          //   borderColor: 'gray.300',
        }}
        onClick={() => navigate(`/listing/${id}`)}
      >
        <Carousel
          swipeable={true}
          infiniteLoop={true}
          showThumbs={false}
          autoPlay={true}
          interval={5000}
        >
          {images.map(image => (
            <div>
              <img
                src={image.imageUrl}
                style={{
                  width: '100%',
                  height: '400px',
                  objectFit: 'cover',
                }}
              />
            </div>
          ))}
        </Carousel>
        <CardBody>
          <Stack spacing="2">
            <Stack
              direction="row"
              divider={<StackDivider borderColor="gray.200" />}
            >
              <Flex align="center">
                <Text fontSize="lg" fontWeight="500" color="gray.500" mr="2">
                  Expected Rent:
                </Text>
                <Text fontSize="lg" fontWeight="700">
                  $
                  {property?.stepOne[3]?.answer
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  /mo
                </Text>
              </Flex>

              <Flex align="center">
                <Text fontSize="lg" fontWeight="500" color="gray.500" mr="2">
                  Home Price:
                </Text>
                <Text fontSize="lg" fontWeight="700">
                  $
                  {property?.stepThree[0]?.answer
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
              </Flex>
            </Stack>

            <Stack
              direction="row"
              divider={<StackDivider borderColor="gray.200" />}
            >
              <Flex align="center">
                <Text fontSize="md" fontWeight="700" mr="1">
                  {property?.stepOne[1]?.answer}
                </Text>
                <Text fontSize="md" fontWeight="500" color="gray.500">
                  bds
                </Text>
              </Flex>

              <Flex align="center">
                <Text fontSize="md" fontWeight="700" mr="1">
                  {property?.stepOne[2]?.answer}
                </Text>
                <Text fontSize="md" fontWeight="500" color="gray.500">
                  ba
                </Text>
              </Flex>

              <Flex align="center">
                <Text fontSize="md" fontWeight="700" mr="1">
                  {property?.stepOne[4]?.answer
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
                <Text fontSize="md" fontWeight="500" color="gray.500">
                  sqft
                </Text>
              </Flex>
            </Stack>

            <Stack
              direction="row"
              divider={<StackDivider borderColor="gray.200" />}
            >
              <Flex align="center">
                <Text fontSize="md" fontWeight="500" color="gray.500">
                  {property?.stepTwo[3]?.answer}, {property?.stepTwo[1]?.answer}
                  , {property?.stepTwo[0]?.answer}
                </Text>
              </Flex>
            </Stack>
          </Stack>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ShowcaseCard;
