import React, { Fragment, useState, useRef, useCallback } from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { useAuth } from '../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import heic2any from 'heic2any';

// chakra
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  NumberInput,
  Progress,
  Stack,
  Flex,
  Select,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  InputGroup,
  InputLeftElement,
  InputLeftAddon,
  Textarea,
  Text,
  IconButton,
  Icon,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  SimpleGrid,
  Image,
  Container,
  Spacer,
} from '@chakra-ui/react';
import { FiTrash2, FiPlus } from 'react-icons/fi';

const AddPropertyStepSix = ({
  setActiveStep,
  isLoading,
  setIsLoading,
  stepSixPropertyImages,
  setStepSixPropertyImages,
  saveProperty,
  setApplicationCompleted,
}) => {
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();
  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();

  const { authUser } = useAuth();
  const navigate = useNavigate();

  const propertyImagesRef = useRef(null);
  const cancelRef = useRef();

  const [alertDialog, setAlertDialog] = useState('');
  const [imageConvertionLoading, setImageConvertionLoading] = useState(false);

  const isValidFileSize = (file, minSizeKB = 5, maxSizeMB = 30) => {
    const fileSizeKB = file.size / 1024;
    const fileSizeMB = fileSizeKB / 1024;
    return fileSizeKB >= minSizeKB && fileSizeMB <= maxSizeMB;
  };

  const isDuplicateFile = (existingFiles, newFile) => {
    return existingFiles.some(
      file => file.name === newFile.name && file.size === newFile.size
    );
  };

  const handleAddMoreFiles = async (
    setFieldValue,
    fieldName,
    existingFiles,
    newFiles
  ) => {
    const validAndUniqueFiles = [];

    for (let i = 0; i < newFiles.length; i++) {
      let newFile = newFiles[i];

      newFile = await convertHeicToJpeg(newFile);

      if (!isValidFileSize(newFile)) {
        setAlertDialog(
          `${newFile.name} has an invalid file size. File size should be between 5kb and 30mb`
        );
        alertOnOpen();

        continue;
      }

      if (isDuplicateFile(existingFiles, newFile)) {
        setAlertDialog(
          `${newFile.name} is a duplicate file. Please attach a new one`
        );
        alertOnOpen();

        continue;
      }

      validAndUniqueFiles.push(newFile);
    }

    if (validAndUniqueFiles.length > 0) {
      const updatedFiles = [...existingFiles, ...validAndUniqueFiles];
      setFieldValue(fieldName, updatedFiles);
    }
  };

  const handleDeleteFile = (setFieldValue, values, fieldName, index) => {
    const updatedFiles = [...values.propertyImages];
    updatedFiles.splice(index, 1);
    setFieldValue(fieldName, updatedFiles);
  };

  const handleDescriptionChange = (
    setFieldValue,
    values,
    fieldName,
    index,
    description
  ) => {
    const updatedFiles = [...values.propertyImages];
    updatedFiles[index].description = description;
    setFieldValue(fieldName, updatedFiles);
  };

  const handleSubmit = async () => {
    console.log('Running handleSubmit()');
    try {
      setIsLoading(true);
      await saveProperty(authUser.uid);
      setIsLoading(false);
      modalOnClose();

      setApplicationCompleted(true);
      navigate('/landlord/home');
    } catch (err) {
      console.log('Error in handleSubmit():', err);
    }
  };

  const convertHeicToJpeg = useCallback(async file => {
    if (
      file.type === 'image/heic' ||
      file.name.toLowerCase().endsWith('.heic')
    ) {
      try {
        const jpegBlob = await heic2any({
          blob: file,
          toType: 'image/jpeg',
          quality: 0.8,
        });
        return new File([jpegBlob], file.name.replace(/\.heic$/i, '.jpg'), {
          type: 'image/jpeg',
        });
      } catch (error) {
        console.error('Error converting HEIC to JPEG:', error);
        return file;
      }
    }
    return file;
  }, []);

  return (
    <Fragment>
      <Formik
        initialValues={{
          propertyImages: stepSixPropertyImages,
        }}
        validationSchema={yup.object({
          propertyImages: yup
            .array()
            .min(1, 'At least 1 Property Image is required')
            .max(30, 'Max 30 Property Images are allowed')
            .required('Adding a Property Image is required'),
        })}
        onSubmit={async values => {
          try {
            setStepSixPropertyImages(values.propertyImages);
            modalOnOpen();
          } catch (err) {
            console.log(err);
            setIsLoading(false);
          }
        }}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing="5">
              <Card bg="white" size="lg">
                <CardBody>
                  <Heading as="h4" size="md" mb="5">
                    Add Property Photos
                  </Heading>
                  <Stack spacing="5">
                    <FormControl
                      isInvalid={
                        formik.errors.propertyImages &&
                        formik.touched.propertyImages
                      }
                      isDisabled={isLoading}
                    >
                      <input
                        type="file"
                        ref={propertyImagesRef}
                        id="propertyImages"
                        name="propertyImages"
                        multiple={true}
                        accept="image/jpeg,image/gif,image/png,image/x-eps,image/jpg,image/*,.heic"
                        style={{ display: 'none' }}
                        onChange={async e => {
                          try {
                            setImageConvertionLoading(true);
                            await handleAddMoreFiles(
                              formik.setFieldValue,
                              'propertyImages',
                              formik.values.propertyImages,
                              e.target.files
                            );

                            propertyImagesRef.current.value = '';
                            propertyImagesRef.current.click();
                            setImageConvertionLoading(false);
                          } catch (err) {
                            console.log(err);
                            setAlertDialog(err);
                            alertOnOpen();
                            setImageConvertionLoading(false);
                          }
                        }}
                      />

                      {formik.values.propertyImages.length === 0 ? (
                        <Flex align="center">
                          <Button
                            as="label"
                            htmlFor="propertyImages"
                            leftIcon={<Icon as={FiPlus} />}
                            isDisabled={isLoading}
                            isLoading={imageConvertionLoading}
                            w="100%"
                          >
                            <Text>Upload Property Images</Text>
                          </Button>
                        </Flex>
                      ) : (
                        <Box>
                          <Flex align="center" mb="5">
                            <Button
                              as="label"
                              htmlFor="propertyImages"
                              leftIcon={<Icon as={FiPlus} />}
                              isDisabled={isLoading}
                              isLoading={imageConvertionLoading}
                              w="100%"
                            >
                              <Text>Add More Property Images</Text>
                            </Button>
                          </Flex>

                          <SimpleGrid columns={1} spacing={5}>
                            {formik.values.propertyImages.map((file, index) => (
                              <Flex
                                boxShadow="md"
                                borderRadius="5px"
                                key={index}
                                w="100%"
                              >
                                <Image
                                  objectFit="cover"
                                  src={URL.createObjectURL(file)}
                                  borderRadius="5px 0 0 5px"
                                  maxW="200px"
                                />
                                <Flex w="100%" p="4" flexDirection="column">
                                  <Text fontWeight="600" mr="2">
                                    {file.name.length > 30
                                      ? file.name.slice(0, 30) + '..'
                                      : file.name}
                                  </Text>

                                  <Textarea
                                    placeholder="Add a description (optional)"
                                    mt="3"
                                    mb="3"
                                    value={file.description}
                                    onChange={e =>
                                      handleDescriptionChange(
                                        formik.setFieldValue,
                                        formik.values,
                                        'propertyImages',
                                        index,
                                        e.target.value
                                      )
                                    }
                                    isDisabled={isLoading}
                                    maxLength={255}
                                  />

                                  <Spacer />

                                  <Flex>
                                    <Spacer />
                                    <IconButton
                                      colorScheme="red"
                                      aria-label="delete file"
                                      icon={<Icon as={FiTrash2} />}
                                      onClick={() => {
                                        handleDeleteFile(
                                          formik.setFieldValue,
                                          formik.values,
                                          'propertyImages',
                                          index
                                        );
                                      }}
                                      isDisabled={
                                        isLoading && imageConvertionLoading
                                      }
                                    />
                                  </Flex>
                                </Flex>
                              </Flex>
                            ))}
                          </SimpleGrid>
                        </Box>
                      )}

                      <Flex align="center" mt="2" direction="column">
                        <FormHelperText>
                          Add clear and nicely captured photos for all the
                          bedrooms, bathrooms, Kitchen, Garage, frontyard/
                          backyard with descriptions. The listings with nice
                          pictures get a customer <b>FIVE</b> times faster.
                        </FormHelperText>
                        <FormErrorMessage>
                          {formik.errors.propertyImages}
                        </FormErrorMessage>
                      </Flex>
                    </FormControl>
                  </Stack>
                </CardBody>
              </Card>

              <Stack justify="right" direction="row" spacing="3">
                <Button
                  isDisabled={isLoading}
                  onClick={() => {
                    setActiveStep(5);
                  }}
                >
                  Prev
                </Button>
                <Button
                  colorScheme="green"
                  // isDisabled={salesCma === '' && appraisalReport === ''}
                  isLoading={isLoading}
                  type="submit"
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>

      <AlertDialog
        isOpen={alertIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={alertOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Oops!
            </AlertDialogHeader>

            <AlertDialogBody>{alertDialog}</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="gray" onClick={alertOnClose} ml={3}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal
        isOpen={modalIsOpen}
        onClose={isLoading !== true ? modalOnClose : null}
        isLoading={isLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you want to submit?</ModalHeader>
          <ModalCloseButton isDisabled={isLoading} />
          <ModalBody>
            <Box>
              <Text>
                Once you submit, you will not be able to edit the details.
              </Text>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              mr={3}
              onClick={async () => {
                await handleSubmit();
              }}
              isLoading={isLoading}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default AddPropertyStepSix;
