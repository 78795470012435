import React, { Fragment, useEffect, useState } from 'react';

// context
import { useAgentAuth } from '../../../context/AgentContext';

// components
import AgentDashboardSidebar from '../../Sidebar';

// chakra
import { CardBody, Flex, Stack, Text, Card, Box } from '@chakra-ui/react';

const LandlordInvite = () => {
  const { getAgentLandlordReferrals } = useAgentAuth();
  const [referrals, setReferrals] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAgentLandlordReferrals();
        setReferrals(data);
      } catch (err) {
        console.log(err);
        setReferrals([]);
      }
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Fragment>
      <AgentDashboardSidebar showInvite={false} showHeader={false}>
        <Box>
          <Text fontSize="xl" fontWeight="bold">
            Invited Landlords
          </Text>

          <Stack spacing="5" mt="5">
            {referrals.map(referral => (
              <Card>
                <CardBody>
                  <Text>Email: {referral?.email}</Text>
                  <Text>Application Status: Started</Text>
                </CardBody>
              </Card>
            ))}
          </Stack>
        </Box>
      </AgentDashboardSidebar>
    </Fragment>
  );
};

export default LandlordInvite;
