import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import SidebarWithHeader from '../../../components/SidebarWithHeader';
import AddPropertyApplication from './AddPropertyApplication/AddPropertyApplication';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  Spacer,
  Badge,
  Box,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

const AddProperty = () => {
  const [applicationCompleted, setApplicationCompleted] = useState(null);

  return (
    <Fragment>
      <SidebarWithHeader userMode="home">
        <Container maxW="container.sm">
          {applicationCompleted ? (
            <Box>Application Finished</Box>
          ) : (
            <AddPropertyApplication
              setApplicationCompleted={setApplicationCompleted}
            />
          )}
        </Container>
      </SidebarWithHeader>
    </Fragment>
  );
};

export default AddProperty;
