import React, { Fragment } from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

// chakra
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  NumberInput,
  Progress,
  Stack,
  Flex,
  Select,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react';

const AddPropertyStepOne = ({
  setActiveStep,
  stepOnePropertyType,
  setStepOnePropertyType,
  stepOneNumberOfBedrooms,
  setStepOneNumberOfBedrooms,
  stepOneNumberOfBathrooms,
  setStepOneNumberOfBathrooms,
  stepOneMonthlyRent,
  setStepOneMonthlyRent,
  stepOneSquareFeet,
  setStepOneSquareFeet,
  stepOneYearBuilt,
  setStepOneYearBuilt,
  stepOneLotSize,
  setStepOneLotSize,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Fragment>
      <Formik
        initialValues={{
          propertyType: stepOnePropertyType,
          numberOfBedrooms: stepOneNumberOfBedrooms,
          numberOfBathrooms: stepOneNumberOfBathrooms,
          monthlyRent: stepOneMonthlyRent,
          squareFeet: stepOneSquareFeet,
          yearBuilt: stepOneYearBuilt,
          lotSize: stepOneLotSize,
        }}
        validationSchema={yup.object({
          propertyType: yup.string().required('Required'),
          numberOfBedrooms: yup.string().required('Required'),
          numberOfBathrooms: yup.string().required('Required'),
          monthlyRent: yup
            .number()
            .min(100, 'Property must have a monthly rent higher that $100')
            .max(
              1000000,
              'Currently taking properties with a monthly rent less that $1,000,000'
            )
            .required('Required'),
          squareFeet: yup
            .number()
            .min(100, 'Usable living space must be at least 100 Sq Ft')
            .max(100000, 'Usable living space must be at most 100,000 Sq Ft')
            .required('Required'),
          yearBuilt: yup
            .number()
            .min(1900, 'Must be greater than 1900')
            .max(
              new Date().getFullYear(),
              'Cannot be greater than current year'
            )
            .required('Required'),
          lotSize: yup
            .number()
            .min(100, 'Lot size must be at least 100 Sq Ft')
            .max(100000, 'Lot size must be at most 100,000 Sq Ft')
            .required('Required'),
        })}
        onSubmit={async values => {
          try {
            if (values.propertyType !== 'single-family home') {
              onOpen();
            } else {
              setStepOnePropertyType(values.propertyType);
              setStepOneNumberOfBedrooms(values.numberOfBedrooms);
              setStepOneNumberOfBathrooms(values.numberOfBathrooms);
              setStepOneMonthlyRent(values.monthlyRent);
              setStepOneSquareFeet(values.squareFeet);
              setStepOneYearBuilt(values.yearBuilt);
              setStepOneLotSize(values.lotSize);
              setActiveStep(2);
            }
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing="5">
              <Card bg="white" size="lg">
                <CardBody>
                  <Heading as="h4" size="md" mb="5">
                    Add Property
                  </Heading>
                  <Stack spacing="5">
                    <FormControl
                      isInvalid={
                        formik.errors.propertyType &&
                        formik.touched.propertyType
                      }
                    >
                      <FormLabel color="gray">Property Type</FormLabel>
                      <Field
                        as={Select}
                        borderWidth="2px"
                        size="lg"
                        id="propertyType"
                        name="propertyType"
                        placeholder="Select Property Type"
                      >
                        <option value="single-family home">
                          Single-family home
                        </option>
                        <option value="multi-family home">
                          Multi-family home
                        </option>
                        <option value="condominium">Condominium</option>
                        <option value="apartment">Apartment</option>
                        <option value="mobile/manufactured home">
                          Mobile/Manufactured home
                        </option>
                        <option value="townhouse">Townhouse</option>
                        <option value="dorm/room/student housing">
                          Dorm/Room/Student housing
                        </option>
                        <option value="other">Other</option>
                      </Field>
                      <FormErrorMessage>
                        {formik.errors.propertyType}
                      </FormErrorMessage>
                    </FormControl>

                    <Flex gap="5">
                      <FormControl
                        isInvalid={
                          formik.errors.numberOfBedrooms &&
                          formik.touched.numberOfBedrooms
                        }
                      >
                        <FormLabel color="gray">Bedrooms</FormLabel>
                        <Field
                          as={Select}
                          borderWidth="2px"
                          size="lg"
                          id="numberOfBedrooms"
                          name="numberOfBedrooms"
                          placeholder="Select number of bedrooms"
                        >
                          <option value="studio">Studio</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="10+">10+</option>
                        </Field>
                        <FormErrorMessage>
                          {formik.errors.numberOfBedrooms}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          formik.errors.numberOfBathrooms &&
                          formik.touched.numberOfBathrooms
                        }
                      >
                        <FormLabel color="gray">Baths</FormLabel>
                        <Field
                          as={Select}
                          borderWidth="2px"
                          size="lg"
                          id="numberOfBathrooms"
                          name="numberOfBathrooms"
                          placeholder="Select number of bathrooms"
                        >
                          <option value="1">1</option>
                          <option value="1.5">1.5</option>
                          <option value="2">2</option>
                          <option value="2.5">2.5</option>
                          <option value="3">3</option>
                          <option value="3.5">3.5</option>
                          <option value="4">4</option>
                          <option value="4.5">4.5</option>
                          <option value="5">5</option>
                          <option value="5.5">5.5</option>
                          <option value="6">6</option>
                          <option value="6+">6+</option>
                        </Field>
                        <FormErrorMessage>
                          {formik.errors.numberOfBathrooms}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>

                    <Flex gap="5">
                      <FormControl
                        isInvalid={
                          formik.errors.lotSize && formik.touched.lotSize
                        }
                      >
                        <FormLabel color="gray">Lot Size</FormLabel>
                        <InputGroup size="lg">
                          <Field
                            as={Input}
                            borderWidth="2px"
                            size="lg"
                            id="lotSize"
                            name="lotSize"
                            type="number"
                            max={100000}
                          />
                          <InputRightAddon>Sq Ft</InputRightAddon>
                        </InputGroup>
                        <FormErrorMessage>
                          {formik.errors.lotSize}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          formik.errors.squareFeet && formik.touched.squareFeet
                        }
                      >
                        <FormLabel color="gray">Usable Living Space</FormLabel>
                        <InputGroup size="lg">
                          <Field
                            as={Input}
                            borderWidth="2px"
                            size="lg"
                            id="squareFeet"
                            name="squareFeet"
                            type="number"
                            min={10}
                            max={100000}
                          />
                          <InputRightAddon>Sq Ft</InputRightAddon>
                        </InputGroup>
                        <FormErrorMessage>
                          {formik.errors.squareFeet}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>

                    <Flex gap="5">
                      <FormControl
                        isInvalid={
                          formik.errors.monthlyRent &&
                          formik.touched.monthlyRent
                        }
                      >
                        <FormLabel color="gray">Monthly Rent</FormLabel>
                        <InputGroup size="lg">
                          <InputLeftAddon>$</InputLeftAddon>
                          <Field
                            as={Input}
                            borderWidth="2px"
                            size="lg"
                            id="monthlyRent"
                            name="monthlyRent"
                            type="number"
                            min={100}
                            max={1000000}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {formik.errors.monthlyRent}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          formik.errors.yearBuilt && formik.touched.yearBuilt
                        }
                      >
                        <FormLabel color="gray">Year Built</FormLabel>
                        <Field
                          as={Input}
                          borderWidth="2px"
                          size="lg"
                          id="yearBuilt"
                          name="yearBuilt"
                          type="number"
                          min={1900}
                          max={new Date().getFullYear()}
                        />
                        <FormErrorMessage>
                          {formik.errors.yearBuilt}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Stack>
                </CardBody>
              </Card>
              <Stack justify="right" direction="row" spacing="3">
                <Button
                  colorScheme="blue"
                  type="submit"
                  // isDisabled={!formik.isValid || !formik.dirty}
                >
                  Next
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Unsupported Property Type</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>We currently only support single-family homes</Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default AddPropertyStepOne;
