import React, { useEffect, useState, Fragment } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { numberWithCommas } from '../../../utils/generalFunctions';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// context
import { useAgentAuth } from '../../../context/AgentContext';
import { useAuth } from '../../../context/AuthContext';
import { useLandlord } from '../../../context/LandlordContext';

// components
import ClientInvitationButton from '../../../components/Agents/ClientInvitation';
import ClientReminderButton from '../../../components/Agents/ClientReminder';
import AgentDashboardSidebar from '../../Sidebar';

// chakra
import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
  Spacer,
  Container,
  SimpleGrid,
  StackDivider,
  Badge,
  Center,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

const Properties = () => {
  const navigate = useNavigate();
  const { authUser } = useAuth();

  const { landlordProperties, getLandlordProperties } = useLandlord();
  const [propertyList, setPropertyList] = useState([]);
  const [propertiesLoaded, setPropertiesLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPropertiesLoaded(false);
        const properties = await getLandlordProperties(authUser?.uid);
        setPropertyList(properties);
        setPropertiesLoaded(true);
      } catch (err) {
        console.log(err);
        setPropertiesLoaded(true);
      }
    };

    fetchData().catch(console.error);
  }, [authUser]);

  return (
    <AgentDashboardSidebar showHeader={false}>
      <Container maxW="container.md">
        <Flex direction={'column'}>
          <Flex align={'center'} mb="5">
            <Text fontSize="lg" fontWeight="600">
              Listed Properties:
            </Text>
            <Spacer />
            <Button
              leftIcon={<AddIcon />}
              colorScheme="blue"
              onClick={() => navigate('/agents/properties/add-property')}
            >
              Add Property
            </Button>
          </Flex>

          {propertiesLoaded ? (
            <Fragment>
              {propertyList.length == 0 ? (
                <Card>
                  <CardBody>
                    <Text>No properties listed</Text>
                  </CardBody>
                </Card>
              ) : (
                <Fragment>
                  <SimpleGrid columns={1} spacing={10}>
                    {propertyList.map(property => (
                      <Card key={property.id}>
                        <Carousel
                          swipeable={true}
                          infiniteLoop={true}
                          showThumbs={false}
                        >
                          {property?.images.map(image => (
                            <div>
                              {image.imageUrl
                                .toLowerCase()
                                .endsWith('.heic') ? (
                                <div
                                  style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '300px',
                                    objectFit: 'cover',
                                  }}
                                >
                                  <h3>HEIC image (preview not available)</h3>
                                </div>
                              ) : (
                                <img
                                  src={image?.imageUrl}
                                  style={{
                                    width: '100%',
                                    height: '300px',
                                    objectFit: 'cover',
                                  }}
                                />
                              )}

                              {image.imageDescription && (
                                <p className="legend">
                                  {image?.imageDescription}
                                  <Fragment>{console.log({ image })}</Fragment>
                                </p>
                              )}
                            </div>
                          ))}
                        </Carousel>
                        <CardBody>
                          <Stack spacing="2">
                            <Stack
                              direction="row"
                              divider={<StackDivider borderColor="gray.200" />}
                            >
                              <Flex align="center">
                                <Text
                                  fontSize="lg"
                                  fontWeight="500"
                                  color="gray.500"
                                  mr="2"
                                >
                                  Expected Rent:
                                </Text>
                                <Text fontSize="lg" fontWeight="700">
                                  $
                                  {property?.data?.stepOne[3]?.answer
                                    ? numberWithCommas(
                                        property?.data?.stepOne[3]?.answer
                                      )
                                    : 'N/A'}
                                  /mo
                                </Text>
                              </Flex>

                              <Flex align="center">
                                <Text
                                  fontSize="lg"
                                  fontWeight="500"
                                  color="gray.500"
                                  mr="2"
                                >
                                  Home Price:
                                </Text>
                                <Text fontSize="lg" fontWeight="700">
                                  $
                                  {property?.data?.stepThree[0]?.answer
                                    ? numberWithCommas(
                                        property?.data?.stepThree[0]?.answer
                                      )
                                    : 'N/A'}
                                </Text>
                              </Flex>
                            </Stack>

                            <Stack
                              direction="row"
                              divider={<StackDivider borderColor="gray.200" />}
                            >
                              <Flex align="center">
                                <Text fontSize="md" fontWeight="700" mr="1">
                                  {property?.data?.stepOne[1]?.answer
                                    ? property?.data?.stepOne[1]?.answer
                                    : 'N/A'}
                                </Text>
                                <Text
                                  fontSize="md"
                                  fontWeight="500"
                                  color="gray.500"
                                >
                                  bds
                                </Text>
                              </Flex>

                              <Flex align="center">
                                <Text fontSize="md" fontWeight="700" mr="1">
                                  {property?.data?.stepOne[2]?.answer
                                    ? property?.data?.stepOne[2]?.answer
                                    : 'N/A'}
                                </Text>
                                <Text
                                  fontSize="md"
                                  fontWeight="500"
                                  color="gray.500"
                                >
                                  ba
                                </Text>
                              </Flex>

                              <Flex align="center">
                                <Text fontSize="md" fontWeight="700" mr="1">
                                  {property?.data?.stepOne[4]?.answer
                                    ? numberWithCommas(
                                        property?.data?.stepOne[4]?.answer
                                      )
                                    : 'N/A'}
                                </Text>
                                <Text
                                  fontSize="md"
                                  fontWeight="500"
                                  color="gray.500"
                                >
                                  sqft
                                </Text>
                              </Flex>
                            </Stack>

                            <Stack
                              direction="row"
                              divider={<StackDivider borderColor="gray.200" />}
                            >
                              <Flex align="center">
                                <Text
                                  fontSize="md"
                                  fontWeight="500"
                                  color="gray.500"
                                >
                                  {property?.data?.stepTwo[3]?.answer
                                    ? property?.data?.stepTwo[3]?.answer
                                    : 'N/A'}
                                </Text>
                              </Flex>

                              <Flex align="center">
                                <Text
                                  fontSize="md"
                                  fontWeight="500"
                                  color="gray.500"
                                >
                                  {property?.data?.stepTwo[0]?.answer
                                    ? property?.data?.stepTwo[0]?.answer
                                    : 'N/A'}
                                </Text>
                              </Flex>

                              <Flex align="center">
                                <Text
                                  fontSize="md"
                                  fontWeight="500"
                                  color="gray.500"
                                >
                                  {property?.data?.stepTwo[1]?.answer
                                    ? property?.data?.stepTwo[1]?.answer
                                    : 'N/A'}
                                </Text>
                              </Flex>
                            </Stack>

                            <Flex align="center">
                              <Text
                                fontSize="md"
                                fontWeight="500"
                                color="gray.500"
                                mr="1"
                              >
                                Status:
                              </Text>
                              <Badge fontSize="0.8em" fontWeight="bold">
                                {property?.data?.status === 'IN_REVIEW'
                                  ? 'In Review'
                                  : property?.data?.status === 'ACCEPTED'
                                  ? 'Accepted'
                                  : 'Rejected'}
                              </Badge>
                            </Flex>
                          </Stack>
                        </CardBody>
                      </Card>
                    ))}
                  </SimpleGrid>
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Text>
              <Card>
                <CardBody>
                  <Center>
                    <Spinner />
                  </Center>
                </CardBody>
              </Card>
            </Text>
          )}
        </Flex>
      </Container>
    </AgentDashboardSidebar>
  );
};

export default Properties;
