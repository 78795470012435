import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

// chakra
import {
  Avatar,
  Box,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { FcInvite } from 'react-icons/fc';
import {
  FiChevronDown,
  FiCompass,
  FiFile,
  FiHome,
  FiList,
  FiMenu,
  FiSettings,
  FiStar,
  FiTrendingUp,
  FiUserPlus,
} from 'react-icons/fi';
import { useAuth } from '../context/AuthContext';
import { USER_TYPES } from '../utils/constants';

const LinkItems = [
  { name: 'Home', icon: FiHome },
  { name: 'Trending', icon: FiTrendingUp },
  { name: 'Explore', icon: FiCompass },
  { name: 'Favourites', icon: FiStar },
  { name: 'Settings', icon: FiSettings },
];

const HomeItems = [
  { name: 'Home', icon: FiHome },
  { name: 'Settings', icon: FiSettings },
];

const OnboardingItems = [{ name: 'Onboarding', icon: FiList, link: '/' }];
const HomebuyerAuthedItems = [
  { name: 'Home', icon: FiHome, link: '/' },
  { name: 'Documents', icon: FiFile, link: '/documents' },
  { name: 'Agent', icon: FiUserPlus, link: '/add-agent' },
  { name: 'Invite & Earn', icon: FcInvite, link: '/homebuyer-invite' },
  // { name: 'Application', icon: GrStatusInfo, link: '/application/status' },
];
const LandlordAuthedItems = [
  { name: 'Home', icon: FiHome, link: '/landlord/home' },
  // { name: 'Application', icon: GrStatusInfo, link: '/application/status' },
];

export default function SidebarWithHeader({ userMode, children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
        userMode={userMode}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} userMode={userMode} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} userMode={userMode} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, userMode, ...rest }) => {
  const { authUser } = useAuth();

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Link
          onClick={() => {
            window.location.assign('https://www.utopiahomes.us/');
          }}
        >
          <Image
            src="https://i.imgur.com/avkbaJm.png"
            alt="Utopia Logo"
            maxW="100px"
          />
        </Link>

        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {userMode === 'home' &&
        HomeItems.map(link => (
          <NavItem key={link.name} icon={link.icon}>
            {link.name}
          </NavItem>
        ))}
      {userMode === 'onboarding' && (
        <Fragment>
          {authUser?.data?.applicationCompleted ? (
            <Fragment>
              {authUser?.data?.type == USER_TYPES.HOME_OWNER ? (
                <Fragment>
                  {HomebuyerAuthedItems.map(link => (
                    <NavItem key={link.name} icon={link.icon} link={link.link}>
                      {link.name}
                    </NavItem>
                  ))}
                </Fragment>
              ) : authUser?.data?.type == USER_TYPES.LANDLORD ? (
                <Fragment>
                  {LandlordAuthedItems.map(link => (
                    <NavItem key={link.name} icon={link.icon} link={link.link}>
                      {link.name}
                    </NavItem>
                  ))}
                </Fragment>
              ) : (
                <Fragment></Fragment>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {OnboardingItems.map(link => (
                <NavItem key={link.name} icon={link.icon} link={link.link}>
                  {link.name}
                </NavItem>
              ))}
            </Fragment>
          )}
        </Fragment>
      )}
    </Box>
  );
};

const NavItem = ({ icon, link, children, ...rest }) => {
  const navigate = useNavigate();
  return (
    <Link
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      onClick={() => {
        navigate(link);
      }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, userMode, ...rest }) => {
  const { handleSignOut, authUser } = useAuth();

  const authedUserType =
    authUser?.data?.type == USER_TYPES.AGENT
      ? 'Agent'
      : authUser?.data?.type == USER_TYPES.LANDLORD
      ? 'Landlord'
      : authUser?.data?.type == USER_TYPES.HOME_OWNER
      ? 'Homebuyer'
      : 'Homebuyer';
  const name = authUser?.displayName
    ? authUser?.displayName
    : authUser?.data?.legalName?.firstName
    ? authUser?.data?.legalName?.firstName +
      ' ' +
      authUser?.data?.legalName?.lastName
    : '';

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Image
        display={{ base: 'flex', md: 'none' }}
        src="https://i.imgur.com/avkbaJm.png"
        alt="Utopia Logo"
        maxW="100px"
      />

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar
                  size={'sm'}
                  src={
                    authUser && authUser.picture
                      ? authUser.picture
                      : 'https://i.imgur.com/NfWnN1q.png'
                  }
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{name ? name : authUser.email}</Text>
                  <Text fontSize="xs" color="gray.600">
                    {authedUserType}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              {userMode == 'home' && (
                <Fragment>
                  <MenuItem>Settings</MenuItem>
                  <MenuDivider />
                </Fragment>
              )}
              <MenuItem
                onClick={async () => {
                  await handleSignOut();
                }}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
