import React, { useEffect, useState, Fragment } from 'react';

// components
import AgentDashboardSidebar from '../../../Sidebar';
import AddPropertyApplication from './AddPropertyApplication/AddPropertyApplication';

// chakra
import { Container, Box } from '@chakra-ui/react';

const AddProperty = () => {
  const [applicationCompleted, setApplicationCompleted] = useState(null);

  return (
    <AgentDashboardSidebar showHeader={false}>
      <Container maxW="container.sm">
        {applicationCompleted ? (
          <Box>Application Finished</Box>
        ) : (
          <AddPropertyApplication
            setApplicationCompleted={setApplicationCompleted}
          />
        )}
      </Container>
    </AgentDashboardSidebar>
  );
};

export default AddProperty;
