import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

// chakra
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Progress,
  Stack,
  Flex,
  FormErrorMessage,
} from '@chakra-ui/react';

const LandlordStepOne = ({
  setActiveStep,
  setFirstName,
  firstName,
  setLastName,
  lastName,
  setPhoneNumber,
  phoneNumber,
  handleNext,
  loading,
}) => {
  return (
    <Fragment>
      <Formik
        initialValues={{
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
        }}
        validationSchema={yup.object({
          firstName: yup.string().required('Required'),
          lastName: yup.string().required('Required'),
          phoneNumber: yup
            .string()
            .required('Required')
            .matches(/^\d{10}$/, 'Phone number must be 10 digits'),
        })}
        onSubmit={async values => {
          setFirstName(values.firstName);
          setLastName(values.lastName);
          setPhoneNumber(values.phoneNumber);
          await handleNext();
        }}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing="5">
              <Card bg="white" size="lg">
                <CardBody>
                  <Heading as="h4" size="md" mb="5">
                    Landlord Details
                  </Heading>
                  <Stack spacing="5">
                    <Flex gap="5">
                      <FormControl
                        isInvalid={
                          formik.errors.firstName && formik.touched.firstName
                        }
                      >
                        <FormLabel color="gray">First Name</FormLabel>
                        <Field
                          as={Input}
                          borderWidth="2px"
                          size="lg"
                          id="firstName"
                          name="firstName"
                          type="text"
                        />
                        <FormErrorMessage>
                          {formik.errors.firstName}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          formik.errors.lastName && formik.touched.lastName
                        }
                      >
                        <FormLabel color="gray">Last Name</FormLabel>
                        <Field
                          as={Input}
                          borderWidth="2px"
                          size="lg"
                          id="lastName"
                          name="lastName"
                          type="text"
                        />
                        <FormErrorMessage>
                          {formik.errors.lastName}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>

                    <Flex>
                      <FormControl
                        isInvalid={
                          formik.errors.phoneNumber &&
                          formik.touched.phoneNumber
                        }
                      >
                        <FormLabel color="gray">Phone Number</FormLabel>
                        <Field
                          as={Input}
                          borderWidth="2px"
                          size="lg"
                          id="phoneNumber"
                          name="phoneNumber"
                          type="tel"
                          placeholder="222 333 4444"
                          // pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                          maxLength="10"
                        />
                        <FormHelperText color="gray.400">
                          Enter 10-digit number
                        </FormHelperText>
                        <FormErrorMessage>
                          {formik.errors.phoneNumber}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Stack>
                </CardBody>
              </Card>
              <Stack justify="right" direction="row" spacing="3">
                <Button
                  colorScheme="green"
                  type="submit"
                  isDisabled={!formik.isValid || !formik.dirty}
                  isLoading={loading}
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default LandlordStepOne;
