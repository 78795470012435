import React, { Fragment } from 'react';

// components
import Navbar from '../components/SearchHomePageComponents/Navbar';
import ShowcaseSection from '../components/SearchHomePageComponents/Showcase/ShowcaseSection';

// chakra
import {
  Box,
  Flex,
  Text,
  Container,
  SimpleGrid,
  Card,
  CardBody,
} from '@chakra-ui/react';

const SearchHomes = () => {
  return (
    <Fragment>
      <Flex direction="column" w="100%" h="100vh">
        <Navbar />
        <ShowcaseSection />
      </Flex>
    </Fragment>
  );
};

export default SearchHomes;
